/********************
IMPORTS
********************/
@import "assets/scss/mixins.scss";

:root {
  /* Primary Color */
  --primary-color-lite: #505050;
  --primary-color-dark: #282828; // hover
  --primary-color-darker: #0d0d0d; // main color
  --primary-color-darkest: #000000; // main color

  /* Secondary Color */
  --secondary-color-lite: #f5d98f;
  --secondary-color-med: #f3c846;
  --secondary-color-dark: #c8800d;

  /* Greys */
  --grey-super-lite: #f2f2f2;
  --grey-lite: #d2d2d2;
  --grey: #A0A0A0;
  --grey-med: #787878;
  --grey-dark: #505050;
  --grey-darker: #282828; // not set
  --grey-darkest: #0d0d0d; // not set

  /* Other */
  --color-success: #0a7305;
  --color-info: #2c28e8;
  --color-error: #b50000;

  --font-black: #000000;
  --font-white: #ffffff;
}

/* Font Family Import */
@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500,700&display=swap");

@font-face{font-display:fallback;font-family:CadillacGothicNarrow;font-style:normal;font-weight:100;src:url("https://brands.gm-cdn.com/gbds/cadillac-2022/fonts/CadillacGothic-NarrowRegular.woff2") format("woff2")}
@font-face{font-display:fallback;font-family:CadillacGothicNarrow;font-style:normal;font-weight:200;src:url("https://brands.gm-cdn.com/gbds/cadillac-2022/fonts/CadillacGothic-NarrowRegular.woff2") format("woff2")}
@font-face{font-display:fallback;font-family:CadillacGothicNarrow;font-style:normal;font-weight:300;src:url("https://brands.gm-cdn.com/gbds/cadillac-2022/fonts/CadillacGothic-NarrowRegular.woff2") format("woff2")}
@font-face{font-display:fallback;font-family:CadillacGothicNarrow;font-style:normal;font-weight:400;src:url("https://brands.gm-cdn.com/gbds/cadillac-2022/fonts/CadillacGothic-NarrowRegular.woff2") format("woff2")}
@font-face{font-display:fallback;font-family:CadillacGothicNarrow;font-style:normal;font-weight:500;src:url("https://brands.gm-cdn.com/gbds/cadillac-2022/fonts/CadillacGothic-NarrowRegular.woff2") format("woff2")}
@font-face{font-display:fallback;font-family:CadillacGothicNarrow;font-style:normal;font-weight:600;src:url("https://brands.gm-cdn.com/gbds/cadillac-2022/fonts/CadillacGothic-NarrowRegular.woff2") format("woff2")}
@font-face{font-display:fallback;font-family:CadillacGothicNarrow;font-style:normal;font-weight:700;src:url("https://brands.gm-cdn.com/gbds/cadillac-2022/fonts/CadillacGothic-NarrowRegular.woff2") format("woff2")}

@font-face{font-display:fallback;font-family:CadillacGothic;font-style:normal;font-weight:100;src:url("https://brands.gm-cdn.com/gbds/cadillac-2022/fonts/CadillacGothic-Regular.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac-2022/fonts/CadillacGothic-Regular.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacGothic;font-style:normal;font-weight:200;src:url("https://brands.gm-cdn.com/gbds/cadillac-2022/fonts/CadillacGothic-Medium.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac-2022/fonts/CadillacGothic-Medium.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacGothic;font-style:normal;font-weight:300;src:url("https://brands.gm-cdn.com/gbds/cadillac-2022/fonts/CadillacGothic-Bold.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac-2022/fonts/CadillacGothic-Bold.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacGothic;font-style:normal;font-weight:400;src:url("https://brands.gm-cdn.com/gbds/cadillac-2022/fonts/CadillacGothic-WideRegular.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac-2022/fonts/CadillacGothic-WideRegular.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacGothic;font-style:normal;font-weight:500;src:url("https://brands.gm-cdn.com/gbds/cadillac-2022/fonts/CadillacGothic-WideMedium.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac-2022/fonts/CadillacGothic-WideMedium.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacGothic;font-style:normal;font-weight:600;src:url("https://brands.gm-cdn.com/gbds/cadillac-2022/fonts/CadillacGothic-WideBold.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac-2022/fonts/CadillacGothic-WideBold.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSans;font-style:normal;font-weight:100;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWeb-Thin.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWeb-Thin.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSans;font-style:italic;font-weight:100;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWeb-ThinItalic.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWeb-ThinItalic.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSans;font-style:normal;font-weight:200;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWeb-Light.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWeb-Light.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSans;font-style:italic;font-weight:200;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWeb-LightItalic.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWeb-LightItalic.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSans;font-style:normal;font-weight:300;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWeb-Book.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWeb-Book.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSans;font-style:italic;font-weight:300;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWeb-BookItalic.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWeb-BookItalic.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSans;font-style:normal;font-weight:400;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWeb-Medium.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWeb-Medium.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSans;font-style:italic;font-weight:400;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWeb-MediumItalic.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWeb-MediumItalic.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSans;font-style:normal;font-weight:800;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWeb-Bold.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWeb-Bold.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSans;font-style:italic;font-weight:800;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWeb-BoldItalic.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWeb-BoldItalic.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSans;font-style:normal;font-weight:100;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWebArabic-Thin.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWebArabic-Thin.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSans;font-style:normal;font-weight:200;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWebArabic-Light.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWebArabic-Light.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSans;font-style:normal;font-weight:300;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWebArabic-Book.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWebArabic-Book.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSans;font-style:normal;font-weight:400;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWebArabic-Medium.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWebArabic-Medium.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSans;font-style:normal;font-weight:600;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWebArabic-SemiBold.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWebArabic-SemiBold.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSans;font-style:normal;font-weight:700;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWebArabic-Bold.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWebArabic-Bold.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSans;font-style:normal;font-weight:800;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWebArabic-ExtraBold.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSansWebArabic-ExtraBold.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSerif;font-style:normal;font-weight:300;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSerifWeb-Regular.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSerifWeb-Regular.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSerif;font-style:italic;font-weight:300;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSerifWeb-RegularItalic.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSerifWeb-RegularItalic.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSerif;font-style:normal;font-weight:400;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSerifWeb-Medium.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSerifWeb-Medium.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSerif;font-style:italic;font-weight:400;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSerifWeb-MediumItalic.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSerifWeb-MediumItalic.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSerif;font-style:normal;font-weight:400;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSerifWebArabic.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSerifWebArabic.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSerif;font-style:normal;font-weight:400;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSerifWebArabic-Medium.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSerifWebArabic-Medium.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSerif;font-style:normal;font-weight:600;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSerifWebArabic-SemiBold.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSerifWebArabic-SemiBold.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSerif;font-style:normal;font-weight:700;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSerifWebArabic-Bold.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSerifWebArabic-Bold.woff") format("woff")}
@font-face{font-display:fallback;font-family:CadillacSerif;font-style:normal;font-weight:900;src:url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSerifWebArabic-Heavy.woff2") format("woff2"), url("https://brands.gm-cdn.com/gbds/cadillac/fonts/CadillacSerifWebArabic-Heavy.woff") format("woff")}

body, html {
  color: var(--font-black);
  font-family: "CadillacGothic", Arial, Helvetica, sans-serif;
  font-weight: 100;
  font-size: var(--f-body-size);
  background-color: white;
  @media (max-width: 767px) {
    background-color: white;
  }
}

:root {
  /* Font Families */
  --font-family: "CadillacGothic", Arial, Helvetica, sans-serif;
  --font-light: 100;
  --font-regular-light: 200;
  --font-regular: 300;
  --font-medium: 500;
  --font-bold: 600;
  --font-x-bold: 700;

   /* Font Sizes */
   --h1-size: 36px;
   --h2-size: 22px;
   --h3-size: 18px;
   --h4-size: 16px;
   --h5-size: 16px;
   --f-body-size: 15px;
   --f-cap-head-size: 15px;
   --f-cap-sub-size: 14px;
   --f-btn-size: 15px;

   // xs = For devices smaller than an iPad
   --h1-size-xs: 30px;
   --h2-size-xs: 20px;
   --h3-size-xs: 16px;
   --h4-size-xs: 16px;
   --h5-size-xs: 15px;

   // xxs = For devices with very small screens, like an iPhone 5
   --h1-size-xxs: 24px;
   --h2-size-xxs: 16px;
   --h3-size-xxs: 16px;
   --h4-size-xxs: 16px;
   --h5-size-xxs: 15px;
   --f-body-size-xxs: 16px;
   --f-cap-head-size-xxs: 12px;
   --f-cap-sub-size-xxs: 12px;
   --f-btn-size-xxs: 12px;

   /* Line Heights */
   --h1-lineheight: 45px;
   --h2-lineheight: 30px;
   --h3-lineheight: 24px;
   --h4-lineheight: 26px;
   --h5-lineheight: 24px;

   /* Line Heights xs devices */
   --h1-lineheight-xs: 36px;
   --h2-lineheight-xs: 26px;
   --h3-lineheight-xs: 24px;
   --h4-lineheight-xs: 24px;
   --h5-lineheight-xs: 24px;

   /* Line Heights xs devices */
   --h1-lineheight-xxs: 32px;
   --h2-lineheight-xxs: 26px;
   --h3-lineheight-xxs: 24px;
   --h4-lineheight-xxs: 24px;
   --h5-lineheight-xxs: 24px;

  /* Font Weights */
  --h1-weight: var(--font-light);
  --h2-weight: var(--font-light);
  --h3-weight: var(--font-light);
  --h4-weight: var(--font-light);
  --h5-weight: var(--font-medium);
  --f-body-weight: var(--font-light);
  --f-cap-head-weight: var(--font-medium);
  --f-cap-sub-weight: var(--font-regular);
  --f-btn-weight: var(--font-medium);

  /* Font Colors */
  --h1-color: var(--grey-darkest);
  --h2-color: var(--grey-darkest);
  --h3-color: var(--grey-darkest);
  --h4-color: var(--grey-darkest);
  --h5-color: var(-grey-darkest);
  --f-body-color: var(--grey-darker);
  --f-cap-head-color: var(--grey-darker);
  --f-cap-sub-color: var(--grey-darker);
  --f-btn-color-lite: white;
  --f-btn-color-dark: var(--grey-darker);

  /* Line Spacing */
  --f-body-space: 24px;
  --f-cap-head-space: 20px;
  --f-cap-sub-space: 16px;

  // xxs - For devices with really small screens, like an iPhone 5
  --f-body-space-xxs: 24px;
  --f-cap-head-space-xxs: 16px;
  --f-cap-sub-space-xxs: 12px;

  .spinner-client-color {
    border-color: var(--primary-color-darker);
  }

  // :focus-visible {
  //   outline: 2px solid var(--grey-darker) !important;
  // }

  // select:focus-visible {
  //   outline: 2px solid var(--grey-darker) !important;
  //   padding-right:3px;
  // }

  /* Global Header Font Styles */
  h1 {
    color: var(--h1-color);
    font-family: "CadillacGothic", sans-serif;
    font-size: var(--h1-size) !important;
    font-weight: var(--h1-weight) !important;
    line-height: var(--h1-lineheight) !important;
    text-transform: uppercase;
    letter-spacing: 9.47px;
    @include breakpoint(xs) {
      font-size: var(--h1-size-xs) !important;
      line-height: var(--h1-lineheight-xs) !important;
    }
    @include breakpoint(xxs) {
      letter-spacing: 1.5px;
      font-size: var(--h1-size-xxs) !important;
      line-height: var(--h1-lineheight-xxs) !important;
    }
  }
  h2 {
    color: var(--h2-color);
    font-family: "CadillacGothic", sans-serif;
    font-size: var(--h2-size) !important;
    font-weight: var(--h2-weight) !important;
    line-height: var(--h2-lineheight) !important;
    text-transform: uppercase;
    letter-spacing: 3.85px;
    @include breakpoint(xs) {
      font-size: var(--h2-size-xs) !important;
      line-height: var(--h2-lineheight-xs) !important;
    }
    @include breakpoint(xs) {
      font-size: var(--h2-size-xxs) !important;
      line-height: var(--h2-lineheight-xxs) !important;
    }
    .superscript {
      position: relative;
      top: -7px;
      font-family: var(--font-family);
    }
  }
  h3 {
    color: var(--h3-color);
    font-family: "CadillacGothic", sans-serif;
    font-size: var(--h3-size) !important;
    font-weight: var(--h3-weight) !important;
    line-height: var(--h3-lineheight) !important;
    text-transform: uppercase;
    letter-spacing: 3.5px;
    @include breakpoint(xs) {
      font-size: var(--h3-size-xs) !important;
      line-height: var(--h3-lineheight-xs) !important;
    }
    @include breakpoint(xs) {
      font-size: var(--h3-size-xxs) !important;
      line-height: var(--h3-lineheight-xxs) !important;
    }

  }
  h4 {
    color: var(--h4-color);
    font-family: "CadillacGothic", sans-serif;
    font-size: var(--h4-size) !important;
    font-weight: var(--h4-weight) !important;
    line-height: var(--h4-lineheight) !important;
    text-transform: uppercase;
    letter-spacing: 1.14px;
    @include breakpoint(xs) {
      font-size: var(--h4-size-xs) !important;
      line-height: var(--h3-lineheight-xs) !important;
    }
    @include breakpoint(xxs) {
      font-size: var(--h4-size-xxs) !important;
      line-height: var(--h3-lineheight-xxs) !important;
    }
  }
  h5 {
    font-family: "CadillacGothic", sans-serif;
    font-size: var(--h5-size) !important;
    font-weight: var(--h5-weight) !important;
    line-height: var(--h5-lineheight) !important;
    color: var(--h5-color);
    @include breakpoint(xs) {
      font-size: var(--h5-size-xs) !important;
      line-height: var(--h5-lineheight-xs) !important;
    }
    @include breakpoint(xxs) {
      font-size: var(--h5-size-xxs) !important;
      line-height: var(--h5-lineheight-xxs) !important;
    }
  }

  /* Global Body Font Styles */

  .font-weight-bold, .bold, strong {
    font-weight: var(--font-regular-light) !important;
    font-family: "CadillacGothic", sans-serif;
  }

  .where-registered {
    font-weight: var(--font-regular) !important;
    font-family: "CadillacGothic", sans-serif;
  }

  a {
    color: var(--primary-color-darker);
    &:hover {
      color:var(--primary-color-dark);
    }
  }

  .bs-tooltip-top .arrow:before {
    border-top-color: var(--grey-darker) !important;
  }
  .bs-tooltip-right .arrow:before {
    border-right-color: var(--grey-darker) !important;
  }
  .bs-tooltip-bottom .arrow:before {
    border-bottom-color: var(--grey-darker) !important;
  }
  .bs-tooltip-left .arrow::before {
    border-left-color: var(--grey-darker) !important;
  }

  mdb-tooltip-container {
    .tooltip-inner {
      background: var(--grey-darker) !important;
      max-width: 350px !important;
      min-width: 300px !important;
      color: var(--grey-lite) !important;
      padding: 0.75rem !important;
      font-size: 14px !important;
    }
  }

  p {
    font-size: var(--f-body-size) !important;
    font-weight: var(--f-body-weight) !important;
    line-height: var(--f-body-space) !important;
    letter-spacing: normal;
    @include breakpoint(xs) {
      font-size: var(--f-body-size-xxs) !important;
      line-height: var(--f-body-space-xxs) !important;
    }
  }
  .caption-header {
    font-size: var(--f-cap-head-size) !important;
    font-weight: var(--f-cap-head-weight) !important;
    color: var(--f-cap-head-color) !important;
    line-height: var(--fi-cap-head-space) !important;
    @include breakpoint(xs) {
      font-size: var(--f-cap-head-size-xxs) !important;
      line-height: var(--fi-cap-head-space-xxs) !important;
    }
  }
  .caption-subheader {
    font-size: var(--f-cap-sub-size) !important;
    font-weight: var(--f-cap-sub-weight) !important;
    color: var(--f-cap-sub-color) !important;
    line-height: var(--fi-cap-sub-space) !important;
    @include breakpoint(xs) {
      font-size: var(--f-cap-sub-size-xxs) !important;
      line-height: var(--fi-cap-sub-space-xxs) !important;
    }
  }

  .disclaimer p {
    font-size: 16px !important;
    font-family: "CadillacGothicNarrow", sans-serif;
  }

  .plans-title-section {
    margin-top: -15rem;
    color: white;
    text-align: center;
    display: block;
    h1 {
      // color: white;
    }
    @include breakpoint(xs) {
      margin-top: -17rem;
    }
    @include breakpoint(xxs) {
      margin-top: -17rem;
    }
    padding-bottom: 3em;
  }

  .alert-warning {
    color: var(--color-error);
    border-color: var(--color-error);
    font-size: 13px;
  }

  .alert-info {
    color: var(--color-info);
    border-color: var(--color-info);
    font-size: 132px;
  }

  input, select, option {
    font-family: "CadillacGothic", sans-serif;
    font-weight: 200;
  }

  .error-message {
    color: var(--color-error) !important;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.75px;
    text-transform: none;
  }
  input.form-control.validate-error.ng-invalid.ng-touched {
    border-bottom: 1px solid var(--color-error);
  }

  mdb-tabset ul.md-tabs.tabs-3 {
    li {
      height: 48px;
      line-height: 48px;
      a {
        padding-top: 10px;
        text-transform: uppercase;

        p {
          letter-spacing: 1px !important;
        }
      }
    }

    .nav-item p {
      padding: 0 !important;
    }
  }

  mdb-tabset .tab-pane i.fa-check {
    color: var(--primary-color-darker);
  }

  app-component-cta {
    a.btn {
      text-align: center !important;
    }
  }

  .btn {
    letter-spacing: 1.6px;
    text-transform: none;
    font-family: "CadillacGothic", sans-serif;
    font-weight: 200;
    line-height: 22px;
    font-size: 14px !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    padding: 12px 26px;
    text-transform: uppercase;
    &.primary-btn {
      background: transparent !important;
      border: 2px solid var(--grey-darkest) !important;
      color: var(--grey-darkest) !important;
      &:hover {
        background: var(--grey-darkest) !important;
        border: 2px solid var(--grey-darkest) !important;
        color: white !important;
      }
      &:disabled {
        color: white !important;
        border: 2px solid var(--grey-lite) !important;
        background: var(--grey-lite) !important;
      }
    }
    &.secondary-btn {
      background: transparent !important;
      border: 1px solid var(--grey-darker) !important;
      color: var(--grey-darker) !important;
      &:hover, &.highlighted {
        background: var(--grey-super-lite) !important;
        color: var(--grey-darker) !important;
      }
      &:disabled {
        background-color: transparent !important;
        border: 1px solid var(--grey-darkest) !important;
        color: var(--grey-darkest) !important;
      }
    }
    &.secondary-btn.plan-button {
      background: transparent !important;
      border: 1px solid var(--grey-darker) !important;
      color: var(--grey-darker) !important;
      &:hover, &.highlighted {
        background: var(--grey-darkest) !important;
        color: var(--font-white) !important;
        border: 1px solid var(--grey-darkest) !important;
      }
      &:disabled {
        background-color: transparent !important;
        border: 1px solid var(--grey-darkest) !important;
        color: var(--grey-darkest) !important;
      }
    }
  }
  .btn-round {
    border-radius: 50% !important;
    position: absolute;
    i {
      left: 13px;
      top: 13px;
      position: absolute;
    }
  }
  app-slice-faq h2, app-slice-faq h3 {
    color: var(--grey-darkest);
    font-weight: 200 !important;
    font-family: sans-serif !important;
    letter-spacing: 0 !important;
  }

  mdb-accordion-item-head h3 {
    color: var(--grey-darker);
    font-weight: 500 !important;
    text-transform: none !important;
  }

  .mobile-plan-footer {
    .btn {
      &.primary-btn {
        color: var(--grey-lite) !important;
        border-color: var(--grey-lite) !important;
      }
    }
    background-color: var(--grey-darkest) !important;
      .plan-name {
        font-weight: 300;
        font-size: 20px;
        font-weight: normal !important;
        letter-spacing: 1px;
      }
  }


  .logo {
    height: 30px;
    @include breakpoint(xs) {
      height: 20px;
    }
  }

  .navbar-brand {
    padding: 0;
    @include breakpoint(xs) {
    }
  }


  .rgba-black-light,
  .rgba-black-light:after {
    background-color: rgba(0, 0, 0, 0);
  }


  /********************
  FOOTER
  ********************/

  .cadillac-disclaimer {
    a {
      font-family: "CadillacGothicNarrow", sans-serif;
      color: white;
      &:hover {
        color: var(--grey-med);
      }
    }
  }

  app-footer-disclaimer .bg-white {
    background-color: var(--primary-color-darkest) !important;
    .text-muted {
      color: white !important;
      .footer-demo-text {
        text-align: center;
        font-family: "CadillacGothicNarrow", sans-serif !important;
      }
    }
  }

  footer {
    background-color: var(--primary-color-darkest) !important;
    .footer-copyright {
      background: var(--primary-color-darkest);
      color: white;
      p {
        font-size: 11px !important;
        line-height: 21px !important;
      }
      a {
        color: white !important;
        font-size: 14px !important;
        width: 100px;
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }
  .subfooter {
    .subfooter-item img {
      padding: 0 !important;
      height: 32px !important;
    }
    a:hover {
      color: #e71519;
    }

    h3 {
      text-transform: uppercase;
      font-size: 1rem !important;
    }
  }

  footer > div {
    background-color: var(--g);
    margin-top: 0;
    width: 100%;
  }
  footer {
    .give_us_a_call {
      margin-top: 20px;
      font-size: 1.5rem;
      font-weight: bold;
    }

    .footer-version {
      font-size: 12px;
    }
  }

  footer .footer-group-logo {
    max-width: 100%;
    width: 100%;
    text-align: center;
    flex: none;
    margin-top: 60px;
    .footer-logo {
      padding: 0 !important;
      img {
        width: 350px;
        @include breakpoint(xs) {
          width: 200px;
        }
      }
    }
  }
  footer .footer-group-contact-us {
    strong {
      display: none;
    }
    .list-unstyled {
      text-align: center !important;
      color: white;
      i.fas {
        color: white;
      }
    }
  }
  .hours-of-operation {
    color: white !important;
  }

  /********************
    LEGAL PAGE
    ********************/

  app-legal-notice .legal,
  app-privacy .legal,
  app-accessibility .legal {
    h1 {
      padding: 10px 0 20px 0;
    }
    h2 {
      padding-top: 40px;
      padding-bottom: 16px;
    }
  }

  /********************
    FAQ SLICE
    ********************/

  .faq-overview {
    margin-top: 10px;
    padding: 30px 0;
    .faq {
      padding: 0 20px;
    }
    .container {
      .faq-header {
        text-align: center;
        margin-bottom: 10px;
      }
      .card-header {
        h5 {
          width: calc(100% - 32px);
        }
      }
      .card-body {
        p {
          font-size: 0.9rem !important;
          font-weight: 300 !important;
          line-height: 1.7 !important;
        }
      }
    }
  }

  .faq .mdb-accordion-indicator::after {
    color: var(--grey-dark);
  }

  .md-accordion .card, .md-accordion .card:first-of-type {
    border: 1px solid var(--grey-lite);
    border-bottom: 1px solid var(--grey-lite);
  }

  mdb-accordion-item-head h3 {
    color: var(--grey-darker);
    text-transform: none;
    font-family: "CadillacSans", sans-serif;
    font-weight: var(--font-medium) !important;
    font-size: var(--h5-size) !important;
  }

  /********************
    PRODUCT PLAN PAGE
    ********************/

  .click-text {
    font-weight: var(--font-regular);
    color: var(--primary-color-darker) !important;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
    white-space: nowrap;
  }

  .customize-plan-wrap {
    .dollarsign {
      font-size: 15px;
      line-height: 20px;
      color: #333;
    }
    .prices {
      color: white;
      background: var(--grey-dark);
      border-radius: 5px !important;
    }
  }
  .switch label input[type="checkbox"]:checked + .lever:after {
    background-color: var(--primary-color-darker);
  }
  .switch label input[type="checkbox"]:checked + .lever {
    background-color: var(--primary-color-darker);
  }
  mdb-select > div > div.single,
  mdb-select.validate-success.ng-valid.ng-touched div.single,
  mdb-select.validate-error.ng-invalid.ng-touched div.single {
    border-bottom: none !important;
    box-shadow: none !important;
    padding-left: 15px;
    div.value {
      color: #333333;
    }
  }
  .mdb-select-toggle {
    right: 15px;
  }
  .mdb-select-toggle {
    margin-top: -0.5rem !important;
  }
  .prices .small {

    font-size: 14px;
    letter-spacing: 1px;
  }
  .mdb-select-toggle {
    color: var(--grey-darker);
  }

  .md-form mdb-select + label {
    padding-left: 5px;
    transform: translateY(-2px);
    transform: translateY(-34px); // to keep label at the top
    &.active {
      transform: translateY(-34px);
    }
  }

  .md-form label {
    transform: translateY(10px);
    transform: translateY(-28px) scale(0.8); // to keep label at the top
    padding-left: 5px;
    color: var(--grey-med) !important;
    &.active {
      transform: translateY(-28px) scale(0.8);
    }
    i {
      color: var(--grey-med) !important;
      font-size: 12px;
    }
    app-tooltip {
      transform: translateY(0) scale(1.25);
      display: inline-block;
    }
  }

  .editBilling .md-form label,
  .editContact .md-form label,
  .editPayment .md-form label {
    transform: translateY(-24px) scale(0.8) !important; // to keep label at the top
    padding-left: 0px;
    color: var(--grey-darkest) !important;
    &.active {
      transform: translateY(-24px) scale(0.8) !important;
      font-size: 1rem !important;
    }
    i {
      color: #212529;
    }
  }

  .switch.success-switch label {
    color: var(--grey-darkest) !important;
    font-size: 13px;
    margin-bottom: 0rem;
    i {
      color: #212529 !important;
    }
  }
 .deductible-label {
    color: var(--grey-darkest) !important;
    font-size: 12px;
    font-weight: var(--font-bold);
  }

  .select-container .deductible-line {
    border-top: 1.5px solid var(--grey);
  }

  .btn.primary-btn.deductible-btn {
    font-size: 14px !important;
    color: var(--font-black) !important;
    letter-spacing: 0px;
    background: white !important;
    border-color: var(--primary-color-darker) !important;
    &:hover {
      background: white !important;
      color: var(--font-black) !important;
    }
    &.active {
      background: var(--grey-darkest) !important;
      color: white !important;
      border-color: var(--grey-darkest) !important;
    }
  }

  .md-form {
    box-shadow: 0 0 3px rgb(0 0 0 / 0.5);
    height: 42px;
  }
  input.form-control {
    width: calc(100% - 10px);
  }
  .customize-plan-wrap ul.stepper {
    .md-form label {
      transform: translateY(-14px) scale(0.8); // to keep label at the top
      padding-left: 5px;
      top: -18px;
    }
    .md-form label.active {
      transform: translateY(-14px) scale(0.8);
    }
  }
  mdb-select > div > div.single.focused {
    border: 1px solid var(--grey-med);
    box-shadow: 0 1px 0 0 var(--grey-med);
  }
  .mdb-select-toggle.focused {
    color: var(--grey-med);
  }

  input.form-control.validate-error.ng-invalid.ng-touched,
  input.form-control.validate-success.ng-valid.ng-touched {
    border-bottom: none !important;
    box-shadow: none !important;
    border: none;
  }
  .md-form input:focus:not([readonly]) {
    border-bottom: none !important;
    box-shadow: none !important;
  }

  .customize-plan-wrap .card {
    border-radius: 5px !important;
  }

  .step-title > span {
    position: absolute;
    left: 5px;

    text-transform: uppercase;
    top: 25px;
    font-size: 12px;
    letter-spacing: 1px;
    color: var(--grey-med);
  }

  ul.stepper.horizontal {
    // overflow: visible !important;
    .step-new-content {
      overflow-x: visible !important;
      overflow-y: visible !important;
    }
  }

  @media only screen and (max-width: 420px) {
    .step-title > span {
      position: relative;
      top: 0;
      left: -8px;
    }
  }

  @media (max-width: 768px) {
    .container-margin {
      margin-top: 30px;
    }
  }

  .step.active .step-title > span {
    color: var(--primary-color-dark);
  }

  i.fa-question-circle, i.fa-info-circle {
    color: var(--grey-med) !important;
    cursor: pointer;
    font-size: 12px !important;
  }


  .form-check-input[type="checkbox"]:checked + label:before {
    border-color: transparent var(--primary-color-dark) var(--primary-color-dark) transparent;
  }

  .justify-content-between.tabbar > a {

    font-size: 14px;
    color: var(--grey-darker) !important;
    &.active {
      color: var(--grey-darkest) !important;
      font-weight: var(--font-bold);
    }
  }

  .vehicle_edit_btn {
    background-color: var(--primary-color-darker) !important;
  }
  .image-border {
    border-color: var(--primary-color-darker) !important;
  }

  .verify-vehicle-header {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    strong {
      font-size: var(--h4-size);
      text-transform: uppercase !important;
      color: var(--primary-color-darker);
      letter-spacing: 3px;
      font-weight: 200 !important;
    }
  }
  .where-vin {
    top: -24px;
    font-weight: var(--font-regular) !important;
    font-size: 13px;
  }

  .blue-link {
    color: var(--primary-color-darker) !important;
    font-weight: var(--font-regular) !important;
  }

  app-my-account-verify {
    .verify-fields {
      height: 42px;
      max-width: 330px;
      margin: auto;
    }

    .md-form label {
      transform: translateY(-24px) scale(0.8);
    }
    &.active {
      transform: translateY(-24px) scale(0.8);
    }
  }


  .modal-content.modal-dynamic form .md-form.mt-3.mb-3 {
    margin-top: 30px !important;
  }

  .customize-plan-wrap .card {
    .terms-conditions {
      position: absolute;
      top: -30px;
      color: var(--primary-color-darker);
      text-transform: uppercase;
      text-align: center;
      left: 0;
      height: 30px;
      background-color: white;
      i {
        margin-right: 10px;
      }
      a {
        text-decoration: none !important;
      }
    }
    .terms-container {
      margin-top: 10px;
      line-height: 20px;
      .small {
        font-size: 12px;
      }
    }
    .payment-container {
      margin-top: 7px;
      line-height: 20px;
      .small {
        font-size: 12px;
      }
    }
    .due-today {
      font-size: 25px;
      font-weight: bold;
    }
    .btn-back {
      background: none;
      color: var(--primary-color-darker) !important;
      box-shadow: none !important;
      border: none !important;
      &:hover {
        color: var(--primary-color-lite) !important;
      }
      &.mobile {
        color: white !important;
      }
    }
  }
  .card-body {
    padding: 16px !important;
    font-weight: var(--font-light);
  }
  @media (max-width: 768px) {
    .customize-plan-wrap .card {
      .prices {
        border-radius: 0 !important;
      }
    }
  }

  @media (min-width: 768px) {
    .card-body {
      padding: 32px !important;
    }
  }

  /********************
    CART PAGE
    ********************/

    .downpayment-dollarsign {
      position: absolute;
      left: 7px;
      top: 8px;
      font-size: 0.95rem;
      @media (min-width: 768px) {
        top: 8px;
      }
    }
    .downpayment-input {
      padding-left: 20px !important;
      width: calc(100% - 20px) !important;
    }

    .downpayment-input-form {
      box-shadow: 0 0 3px rgb(0 0 0 / 50%) !important;
    }

    .payment-options-tabs {

        &.card-body {
          padding: 0px !important;
        }
    }

    .shopping-cart .card-header,
    .shopping-cart hr.fade-line {
      background: transparent;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .shopping-cart {

      padding-top: 40px;

      h2, h3 {
        font-size: 20px !important;
      }
      h2 mdb-icon {
        display: none;
      }

      .md-form label {
        padding-left: 0px;
      }

      .coverage-starts {
        text-transform: uppercase;
        font-weight: var(--font-regular);
        color: var(--grey-dark);
      }

      .coverage-start-date {
        color: var(---primary-color-darker) !important;
      }

      .continue-shopping {
        display: none;
      }
      .your-vehicle {
        padding-top: 0 !important;

      }
    }

    .mobile-product-name {
      letter-spacing: 1px;
      color: var(--primary-color-dark);
    }

  .shopping-cart {
    .product-name, .cart-price {
      color: var(--font-black);
      text-transform: uppercase;
    }
    .cart-item-body {
      padding-left: 0 !important;
      padding-bottom: 10px;
      border-bottom: 1px solid var(--grey);
    }
    .card .card-body .card-text {
      color: var(--font-black);
    }
    .row {
      .media-body {
        border: 1px solid var(--grey);
        h5, .cart-price {
        }
        .font-weight-bold, span strong {
          color: var(--font-black);
          text-transform: uppercase;
          font-weight: var(--font-regular) !important;
        }

        .col-4 {
          position: relative;
          right: 0;
        }
        span {
          font-size: var(--h5-size);
        }
      }
    }
    .remaining-payments, .payment-plan {
      color: var(--primary-color-darker) !important;
    }

    .payment-button {
      font-size: 14px;
      border: 1px solid var(--grey-darker) !important;
      background-color: var(--grey-darker) !important;
      color: white !important;
      &.active {
        background-color: transparent !important;
        color: var(--grey-darker) !important;
      }
    }
    .fa-shopping-cart {
      position: relative;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      font-size: 30px;
    }
  }

  /********************
    SUPPORT PAGE
    ********************/

  .support .solid-hero {
    background: none;
    padding: 100px 0 0 0;
    h2 {
      text-align: center;
      padding: 10px;
    }

    button.primary-btn {
      i {
        color: white;
      }
    }
    button.primary-btn:hover {
      i {
        color: var(--grey-darkest) !important;
      }
    }
  }

  .support .container:not(.support-items) {
    h1 {
      color: black !important;
    }
    h2 {
      padding-top: 40px;
      padding-bottom: 16px;
    }
    p,
    li {
      font-size: 18px !important;
    }
    @include breakpoint(xxs) {
      p,
      li {
        font-size: 16px !important;
      }
      &.pl-5 {
        text-align: center;
        padding-left: 0 !important;
      }
    }
  }
  .support .container.support-items > .row {
    margin-top: 0 !important;
    padding-top: 1rem !important;
    mdb-card {
      // max-width: 312px;
      margin: auto;
      max-height: 350px;
      img {
        width: 30% !important;
      }
      h2 {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
      }
      mdb-card-footer {
        padding-bottom: 15px;
        a.btn {
          box-shadow: none !important;
          &:hover {
            color: white !important;
          }
        }
      }
    }
  }

  .modal-dialog {
    max-width: 575px;
    i.fa-arrow-right {
      display: none;
    }

    .change-vehicle {
      text-align: center;

      color: var(--grey-dark);
      font-size: 14px;
      width: 100%;
      a {

        color: var(--color-info);
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1px;
      }
    }
    .cancel-btn {
      box-shadow: none !important;
      color: var(--primary-color-dark);
      white-space: nowrap;
    }
    .primary-btn {
      white-space: nowrap;
    }
  }
  .form-control {
    border: none !important;
    font-size: 1rem;
    padding: 10px 5px;
    margin-bottom: 8px !important;
  }

  @media (min-width: 1200px) {
    .col-xl-4 {
      flex: none;
      max-width: 100%;
    }
  }

  .btn.disabled,
  .btn:disabled {
    background-color: var(--grey-dark) !important;
  }

  .product-footer {
    background-color: #f9f9f9;
  }

  .product-footer-text {

    font-size: 12px;
    color: var(--color-info);
    line-height: 16px;
  }

  .card-border {
    border-right: 1px solid var(--grey);
  }

  .overline {
    // font-weight: 600;
    color: var(--grey-med);
    font-size: 14px;
  }

  /********************
    VERIFY ACCOUNT
    ********************/

    .verify-account {
      .account-header {
        color: var(--font-black);
        font-size: var(--h2-size) !important;
        font-weight: var(--font-regular) !important;
        text-transform: uppercase;
        font-family: "CadillacGothic", sans-serif;
        letter-spacing: 1px;
      }
      .tab-content .col-12 {
        margin-top: 5px;
        margin-bottom: 5px;
      }
      mdb-tabset > .container-fluid > .row {
        max-width: 430px;
        margin: auto;
      }
      mdb-card.col-md-8.card {
        max-width: 684px;
      }
      p, .card-text {
        color: var(--grey-dark);
      }
      .container-fluid > .row > .col-md-12:first-child {
        padding: 0 3rem;
      }
      ul.md-pills {
        border-bottom: 3px solid var(--grey-darkest);
        border-radius: 0;
      }
    }

    .verify-account {
      margin-top: 50px;
      .card {
        box-shadow: none;
        background-color: transparent;
        input {
          background: white;
        }
      }
    }
    .verify-account .md-pills {
      li {
        padding: 0;
        margin-left: 3px;
        margin-bottom: 2px;
        &:first-child {
          margin-left: 0;
        }
        &.active {
          margin-bottom: -2px;
          margin-top: -2px;
        }
      }
      .nav-link {
        transition: none;
        padding: 5px 1rem;
        border: 1px solid var(--primary-color-darkest);
        letter-spacing: 1px;
        font-size: 16px;
        box-shadow: none !important;
        border-radius: 0 !important;
        color: var(--primary-color-darkest);
        &:hover {
          background-color: transparent;
          color: var(--primary-color-darkest);
        }
        &.active {
          background-color: var(--grey-darkest);
          border: 1px solid var(--grey-darkest);
          color: white;
          padding-top: 8px;
        }
        i.fas {
          display: none;
        }
      }
    }

  /********************
  MY ACCOUNT
  ********************/

  .account-container {
    .vehicle-info-section {
      font-weight: 100;
    }

    .cc-update-section label {
      font-weight: 100;
    }

    .payment-schedule-section .payment-details {
      font-family: Arial, Helvetica, sans-serif;
    }

    .cc-update-section {
      padding-left:2px;
    }
    .account-heading {
      background-color: #efefef;
    }
    .vehicle-tab {
      letter-spacing: normal !important;
      .tab-top {
        padding-top: 15px;
        background-color: transparent;
        border-bottom: none;
        .tab-title {
          color: var(--grey-darkest);
          font-weight: var(--font-bold);
          font-weight: 200;
        }
        .tab-title-info {
          color: var(--grey-darkest);
        }
      }
    }
    .close-section {
      color: var(--grey-darkest);
    }
    .credit-card .cc-name {
      letter-spacing: normal !important;
    }
  }

  .account-container .desktop .vehicle-tab {
    border-left: 4px solid #6D717A;
  }

  .account-container .mobile .vehicle-tab {
    .tab-top {
      padding-top: 25px;
    }
    .tab-top, .tab-bottom {
      padding-left: 25px;
    }
  }

  /********************
    CHECKOUT PAGE
    ********************/

  @media (max-width: 768px) {
    app-checkout {
      mdb-card-body {
        .btn {
          width: 100%;
        }
      }
    }
  }

  app-checkout {
    .checkout .edit-remove-links {
      display: none;
    }

    .coverage-start,
    .items-in-cart {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

  }

  .modal-content i.fa-shopping-cart {
    display: none;
  }

  .shopping-cart {
    .progress {
      background-color: var(--grey-lite);
      .progress-bar {
        background-color: var(--grey-darkest);
      }
    }
  }


  /********************
    NAVBAR
    ********************/

    navlinks {
      padding-top: 4px;
      padding-bottom: 8px;
      @media (max-width: 787px) {
        padding-top: 2px;
        padding-bottom: 2px;
      }
    }

    .navbar .dropdown-toggle {
      font-size: 14px;
      line-height: 20px;
    }

    .navbar a.nav-link,
    .navbar .dropdown-toggle {
      font-weight: 100;
      font-size: 16px !important;
      line-height: 20px;
      cursor: pointer;
      color: var(--grey-darker) !important;
      font-family: "CadillacGothic", Arial, Helvetica, sans-serif;
    }
    .navbar .nav-item {
        margin-left: 25px !important;
        @media (max-width: 992px) {
          margin-left: 10px !important;
        }
      .nav-link {
        padding: 0 !important;
        i.fas {
        padding: 0 0 3px 0 !important;
        }
      }
    }
    .navbar a.nav-link:hover,
    .navbar .dropdown-toggle:hover {
      color: var(--grey-med) !important;
    }

    .navbar .nav-item.active {
      border-bottom: 2px solid var(--primary-color-darker);
    }

    .myaccount .accordion {
      .card-header {
        background-color: transparent !important;
        a {
          border-top: none !important;
          h5 {
          color: #333 !important;
          }
        }
      }
    }


    .sidenav {
      a:first-child {
        border-top: 1px solid #dadada;
      }
     .brand {
        padding: 0 20px 20px 20px;
      }
    }
    .navbar {
      .shopping-cart-icon {
        padding:2px 0 0 0;
        a {
          position: relative;
          display: block;
          @include breakpoint(xs) {
            padding-top: 10px;
          }
          &.waves-effect {
            overflow: visible;
          }
          mdb-icon {
            display: block;
          }
        }
      }

    }

    .navbar {
      .nav-link {
        overflow: visible !important;
        padding: 0;
        .fa-bars {
          padding-top: 10px;
        }
      };
      .call {
        a.phone_number.nav-link {
          color: var(--primary-color-darker) !important;
        }
      }
      .call.desktop {
        a.phone_number {
          position: relative;
          overflow: visible;
          i {
            &:before {
              content: "";
            }
          }
        }
      }
      .call.box {
        border: 1px solid var(--primary-color-darker);
        position: relative;
        margin-top: 3px !important;
        a.phone_number {
          float: right;
          padding: 3px 5px 3px 5px !important;
          color: var(--font-black) !important;
          span {
            margin-right: 3px;
            letter-spacing: 1px;
          }
          i {
            position: relative;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            width: 16px;
            height: 16px;
            transform: scaleX(-1);
          }
        }
      }
    }

    .shopping-cart-icon .badge-primary {
      position: absolute !important;
      color: #fff;
      background-color: var(--primary-color-darker);
      margin-left: 18px;
      margin-top: -33px;
      line-height: 15px;
    }

    @media (max-width: 767px) {
      .navbar {
        .nav-item {
          padding: 0 0 3px 0 !important;
          margin-top: 8px !important;
          .badge-primary {
            margin-left: 18px;
            margin-top: -33px;
            line-height: 15px;
          }
        }
      }
    }

  /********************
    MAIN LANDING PAGE
    ********************/

  .whats-not-covered {
    app-component-text {
      @include breakpoint(xs) {
        .col-12 {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
  }

  .cadillac-main-hero-carousel {
    .content-feature {
      @include breakpoint(xs) {
        padding-top: 0 !important;
        margin-top: -5px !important;
      }
    }
  }


  .cadillac-main-hero {
    .content-feature-top-padding {
      // max-height: 900px !important;
      height: 632px;
      @media (max-width: 767px) {
        background-position: center bottom !important;
      }
    }
    @media (max-width: 767px) {
      app-component-title .col-12 {
        padding-top: 5px !important;
      }
      app-component-text .col-12 {
        visibility: hidden;
      }
    }
  }

  .cadillac-cta-button {
    a{
      color: var(--f-btn-color-lite) !important;
      font-weight: bold;
      &:hover {
        color: var(--grey-med) !important;
      }
    }
    a.btn.secondary-btn{
      letter-spacing: 1px;
      color: #ffffff !important;
      border: 1px solid #ffffff !important;
      font-weight: normal;
      &:hover {
        color: #000000 !important;
      }
    }
  }

  @media (max-width: 767px) {
    app-component-video #media {
      width: 100% !important;
    }
    .icon-group {
      margin-bottom: 24px;
    }
  }
  .icon-bar .call {
      background-color: var(--primary-color-darker);
      .fa-phone {
        line-height: 60px;
      }
  }
  h2.embeddedservice-chatheader_chatheader {
    font-size: 20px !important;
  }

  app-product-home {
    .main-hero-bg {
      @include breakpoint(xs) {
        background-position: 50% 40px;
      }
    }
    > *:nth-child(6), *:nth-child(9) { // 'designed for peace of mind' disclaimers and 'all plans include' title
      .mobile-margin {
        padding-top:0 !important;
        .col-12 {
          padding-top: 24px !important;
        }
      }
    }
  }

  app-product-home {
    .get-the-most-gold-vsp {
      app-component-icon-group {
        padding-bottom: 20px;
        @include breakpoint(xs) {
          padding-bottom: 0;
          .icon-group:nth-child(1) {
            margin-bottom: 42px;
          }
          .icon-group:nth-child(2) {
            margin-bottom: 0;
          }
        }
        .icon-group {
          margin-right: 20px;
        }
        img {
          position: absolute;
          left: -10px;
          top: 5px;
          @include breakpoint(xs) {
            top: 10px;
          }
        }
        .cap-head {
          text-align: left;
          padding-left: 55px;
          @include breakpoint(xs) {
            padding-left: 70px;
          }
        }
      }
    }
  }

  app-product-home .why-vsp {
    .facts .card {
      min-height: 284px;
    }
    .fact-title {
      display: none;
    }
    .card-body {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
  app-product-home app-slice-content-block .peace-of-mind app-component-icon-group .justify-content-center {
    p {
      text-align: left;
      padding-left: 35px;
      p {
        margin-left: -35px;
      }
    }

    .icon-group {
      img {
        position: absolute;
        left: 0;
      }
    }
  }
  @media (max-width: 768px) {
    app-product-home app-slice-content-block .peace-of-mind {
      margin-bottom: 0 !important;
      app-component-icon-group .justify-content-center {
        > div:last-child {
          margin-bottom: 0px !important;
        }
        p {
          padding-left: 50px;
          p {
            margin-left: -50px;
          }
        }
      }
    }
  }

  app-product-home {
    app-slice-content-block .how-it-works .cb-text {
      .title-component .col-12{
        padding-bottom: 0px !important;
      }
      .pb-2 > div > p {
        display: block;
        line-height: 18px !important;
        // min-height: 30px; //Removed for D2CD-688
      }
      app-disclaimer {
        font-family: "CadillacGothicNarrow", sans-serif;
        .pl-4 {
          padding-left: 0 !important;
          .col-12 {
            padding-left: 0 !important;
          }
        }
        .disclaimer {
          font-family: "CadillacGothicNarrow", sans-serif;
          color: var(--grey-dark) !important;
          .superscript {
            margin-left: -15px;
          }
        }
      }
    }
  }

  app-product-home {
    app-slice-content-block .why-vsp {
      .card {
        min-height: 250px !important;
      }
    }
    app-slice-content-block .whats-covered {
      .content-feature:nth-child(2) {
        app-component-text {
          .col-12 {
            padding-top: 43px !important;
            @media (max-width: 768px) {
              padding-top: 65px !important;
            }
          }
        }
      }
      .content-feature:nth-child(3) {
        background-color: rgba(255, 255, 255, 0) !important;
        padding-top: 150px !important;
      }
    }
    .whats-covered .feature-component {
      app-component-text {
        ul {
          padding-left: 18px;
          list-style-image: url("/assets/vroom/icons/Vroom-icon-check.svg");
        }
      }
    }
    app-slice-content-block .whats-covered .content-feature:nth-child(1) {
      min-width: 50%;
      max-width: 50%;
      padding-right: 0 !important;
    }

    app-slice-content-block .whats-covered .content-feature:nth-child(2) {
      app-component-title .col-12 {
        position: absolute;
        min-width: 350px;
        @media (max-width: 768px) {
        padding-top: 24px !important;
        }
      }
      .feature-component:nth-child(2) .cb-text {
        margin-top: 40px;
        @media (max-width: 768px) {
          margin-top: 65px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    app-product-home {
      app-slice-content-block .whats-covered {
        .content-feature:nth-child(3) {
          padding-top: 73px !important;
        }
      }
      app-slice-content-block .whats-covered.content-block-wrap {
        background-color: rgb(245, 245, 245);
      }
      app-slice-content-block .whats-covered .content-feature:nth-child(1) {
        min-width: 100%;
        max-width: 100%;
        padding-right: 0 !important;
      }
    }
    app-product-home {
      app-slice-content-block .worry-free-protection {
        h4 {
          margin-top: 16px;
          text-transform: uppercase;
        }
        h2 {
          margin-bottom: 32px;
        }
        p {
          margin-bottom: 32px;
        }
      }
    }
  }

  .content-block-wrap.whats-covered {
    .cb-text.mobile-margin {
      padding-top: 0px !important;
    }
    .secondary-btn {
      background:rgba(0, 0, 0, 0) !important;
      border: none !important;
      box-shadow: none !important;

      color: var(--color-info) !important;
      text-transform: uppercase;
      font-size: 14px !important;
      letter-spacing: 1px;
      cursor: pointer;
      white-space: nowrap;
    }
  }

  @media (max-width: 767px) {
    .cadillac-get-rates .content-feature {
      padding-bottom: 30px !important;
    }
  }

  .confirmation {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: var(--grey-darkest);
    }
  }

  app-footer-questions {
    .row {
      @media (max-width: 769px) {
        padding: 0 32px;
        .subfooter-item {
          padding: 12px 0;
        }
      }
    }
  }

  /****************
    * Customize Plan
    *****************/

  @media only screen and (min-width: 769px) {
    .my-pricing-background {
      .container {
        h1 {
          max-width: 75%;
        }
      }
    }
  }

  .price-cost {
    font-family: "CadillacGothic", sans-serif;
    font-size: 26px !important;
    color: var(--font-black) !important;
    line-height: 26px;
    font-weight: 600 !important;
    .price-cost-asterisk {
      font-size: 12px !important;
    }
  }

  .select-container .price-plan{
    margin: 40px auto;
  }

  .price-plan {
    .excludes-tax {
      color: var(--grey-darker) !important;
      font-size: 14px !important;
      line-height: 20px !important;
      font-weight: var(--font-light);
    }
  }

  .vehicle-info-container {
    justify-content: center;
  }

  @media only screen and (min-width: 769px) {
    .cadillac-main-hero .vehicle-info-container {
      justify-content: left;
    }
  }


  .vehicle-component .vehicle-information {
    line-height: 1.2;
    font-size: 18px !important;
    text-transform: none;
}

  .plan-select {
    font-size: 16px;
    font-weight: normal;
    color: var(--font-black) !important;
    background-color: transparent;
    border: 1px solid var(--primary-color-darker);
    letter-spacing: 1px;
    margin: 0;
    font-family: "CadillacGothic", sans-serif;
    @media (max-width: 767px) {
      font-size: 11px;
      letter-spacing: 0;
    }
    &:first-child {
      border-left:1px solid var(--primary-color-darker) !important;
    }
    &.active {
      border: none !important;
      border-right: 4px solid white !important;
      border-left: 4px solid white !important;
      background-color: var(--grey-darkest);

      &:first-child {
        margin-left: 0 !important;
        border-left: none !important;
      }
      &:last-child {
        margin-right: 0 !important;
        border-right: none !important;
      }
    }
  }

  .plan-select-border {
    border:none;
    height: 3px;
    margin: 0;
    background-color: var(--grey-darkest);
    position: relative;
    &:first-child{
    }
    &:last-child {
    }
  }

  .coverage-section {
    .click-text {
      color: var(--grey-darkest) !important;
    }
    label {
      color: var(--grey-darker) !important;
    }
  }
  .cr-red {
    background-color: var(--primary-color-darker) !important;
  }

  .customize-container {
    .md-form {
      background-color: white;
    }
  }

  .parts-covered-background {
    .parts {
      .fa-check {
        color: var(--primary-color-dark);
      }
    }
  }

  .customize-container,
  .coverage-overview {
    background: var(--f-btn-color-lite);
  }

  .customize-payments {
    .md-form {
      box-shadow: none;
    }
  }


  /********************
    Cadillac LANDING PAGE
  ********************/

  .content-feature.content-feature-top-padding {
    padding-top: 0 !important;
  }

  .additional-benefits {
    h3 {
      color:white;
    }
    .superscript {
      color:white !important;
    }
  }

  @media only screen and (max-width: 768px) {
    .additional-benefits, .how-protection-works {
      .title-component {
        padding-top:40px;
      }
      app-component-title > div > div, 
      app-component-text > div > div {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .additional-benefits {
        .content-feature {
          padding-top: 0 !important;
        }
      .mobile-margin {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
        app-component-image > div > div {
          height:400px !important;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .cadillac-get-rates .content-feature {
      padding-bottom: 30px !important;
    }
  }


  // @media only screen and (min-width: 641px) and (max-width: 1007px) {
  //   h1 {
  //     font-size: 42px !important;
  //   }
  //   h2 {
  //     font-size: 32px !important;
  //   }
  // }
}


@media only screen and (max-width: 641px) {
  app-component-title .h1 {
    text-align: center;
  }
}

app-checkout-registration-info-state-change-modal {
  .modal-body {
    padding: 32px !important;
  }
  .primary-btn {
    width: 240px !important;
  }

  @media only screen and (max-width: 768px) {
    .primary-btn {
      width: 190px !important;
    }
    .modal-body {
      padding: 16px !important;
    }
  }
}

.cookie-acceptance-modal {
  background-color: var(--grey-darkest);
}

.prismic-grid {
  .full-img-container {
    @media only screen and (max-width: 768px) {
    padding: 0 !important
    }
  }
}

  /****************
  * Okta Widget
  *****************/

  .o-form-button-bar .button-primary, .enter-auth-code-instead-link {
    background: var(--grey-darkest) !important;
    border: 1px solid var(--grey-darkest) !important;
    color: var(--font-white) !important;
    font-weight: 100;
    &:hover {
      background: white !important;
      color: var(--font-black) !important;
    }
  }

  .okta-form-input-field input, .chzn-search input {
    background-color: white;
    // box-shadow: 0 0 3px rgb(0 0 0 / 50%);
    border-bottom: 1px solid #ced4da;
    box-sizing: content-box;
  }

  .okta-form-label {
    label {
      // color: var(--grey-darkest) !important;
      color: #757575;
    }
  }

  .o-form-input-name-rememberMe {
    label {
      &::before {
        border: 2px solid #8a8a8a;
      }
      &::after {
        border: 2px solid #8a8a8a;
      }
    }
    input {
      &:checked {
        &+label:before {
          border: 2px solid #8a8a8a;
          border-color: transparent var(--primary-color-darker) var(--primary-color-darker) transparent;

        }
      }
    }
  }

  .okta-form-infobox-error  {
    color: var(--color-error) !important;
  }

  :root .okta-form-input-error {
    color: var(--color-error) !important;
  }

  #widget-container, #password-reset-container {
    a:not([href]):not([tabindex]) {
      color: var(--primary-color-darkest);
    }
  }
  
  .content-feature.countdown-timer {
    padding-top: 0 !important;
  }
  
  :root app-slice-faq h2 {
    color: var(--grey-darkest);
    font-weight: var(--h2-weight) !important;
    font-family: 'CadillacGothic', sans-serif !important;
    letter-spacing: 3.85px !important;
    line-height: var(--h2-lineheight) !important;
    font-size: var(--h2-size) !important;
  }

  :root app-slice-faq h3 {
    color: var(--grey-darkest);
    font-weight: var(--f-body-weight) !important;
    font-family: 'CadillacGothic', sans-serif !important;
    letter-spacing: 0 !important;
    line-height: 24px !important;
    font-size: var(--f-body-size) !important;
  }